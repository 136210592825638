import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../utils/css/screen.css"
import ReorderImg from "../../content/assets/illustrations/mobile/reorder.svg"
import HistoryImg from "../../content/assets/illustrations/mobile/history.svg"
import CustomImg from "../../content/assets/illustrations/mobile/custom.svg"
import TailorImg from "../../content/assets/illustrations/b2bcloud/promotions.svg"
import TimelyImg from "../../content/assets/illustrations/mobile/timely.svg"
import InstantImg from "../../content/assets/illustrations/mobile/instant.svg"

const AboutPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle} currentLink="/mobilesuite">
      <SEO
        title="iOS and Android mobile apps let you operate online and
        offline in perfect sync"
        description="Our native mobile apps, both iOS and Android, let you operate online and
        offline in perfect sync."
        keywords={[
          `B2B`,
          `B2B Commerce`,
          `AI Sales`,
          `Sales Intelligence`,
          "B2B eCommerce",
          "Sales Artificial Intelligence",
          "B2B AI",
          "B2B Sales AI",
        ]}
      />

      <div className="banner">
        <Img
          fluid={data.benchAccounting.childImageSharp.fluid}
          className="banner-img"
        />
        <div className="banner-body">
          <h1 className="centered caption">Mobile now. Mobile first.</h1>
          <h4>
            Being mobile lets one do more while on the go. You do not have to
            wait for your customer to reach office to get your orders everyday.
            Our native mobile apps (iOS, Android) let you operate online and
            offline in perfect sync.
          </h4>
        </div>
      </div>
      <div className="b2bsections text-center">
        <div className="section sec1">
          <div className="section-txt">
            <h3>Instant Orders.</h3>
            <div className="sec-img">
              <InstantImg />
            </div>
            <h4 className="section-text">
              <p>
                Orders can be placed on-the-go, instantly, essentially reducing
                your order waiting time to zero.
              </p>
            </h4>
          </div>

          <div className="img1">
            <InstantImg />
          </div>
        </div>

        <div className="section sec2">
          <div className="section-txt">
            <h3>Easy Reordering.</h3>
            <div className="sec-img">
              <ReorderImg />
            </div>
            <h4 className="section-text">
              <p>
                One-click reordering, order and inventory tracking for quick B2B
                sales.{" "}
              </p>
            </h4>
          </div>
          <div className="img2">
            <ReorderImg />
          </div>
        </div>
        <div className="section sec3">
          <div className="section-txt">
            <h3>Order History at Customers’ Fingertips.</h3>
            <div className="sec-img">
              <HistoryImg />
            </div>
            <h4 className="section-text">
              <p>
                Your customers can access their order history and other key data
                any time they want.
              </p>
            </h4>
          </div>
          <div className="img3">
            <HistoryImg />
          </div>
        </div>
        <div className="section sec4">
          <div className="section-txt">
            <h3>Customization.</h3>
            <div className="sec-img">
              <CustomImg />
            </div>
            <h4 className="section-text">
              <p>
                The mobile app can be customized according to your brand’s logo
                and colors, giving it a distinctly beautiful look.
              </p>
            </h4>
          </div>
          <div className="img4">
            <CustomImg />
          </div>
        </div>
        <div className="section sec5">
          <div className="section-txt">
            <h3>Up-to-the-minute information.</h3>
            <div className="sec-img">
              <TimelyImg />
            </div>
            <h4 className="section-text">
              <p>
                Real-time integration across all your devices, synced to the
                cloud.
              </p>
            </h4>
          </div>
          <div className="img5">
            <TimelyImg />
          </div>
        </div>
        <div className="section sec6">
          <div className="section-txt">
            <h3>Tailor customer experience.</h3>
            <div className="sec-img">
              <TailorImg />
            </div>
            <h4 className="section-text">
              <p>
                Set up tailored catalogues to only show products you want the
                individual customer to see and automatically apply
                customer-specific price lists and promotions.
              </p>
            </h4>
          </div>
          <div className="img6">
            <TailorImg />
          </div>
        </div>
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(relativePath: { eq: "HeroMobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
